// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "./stream_actions"
import "./turbo_progress_bar"
import "trix"
import "@rails/actiontext"
import * as ActiveStorage from "@rails/activestorage"

ActiveStorage.start()

// FIXME: This monkeypatch allows to break out a frame and redirect the full page
// after submitting a form inside a modal.
// See:
//  https://github.com/hotwired/turbo-rails/pull/367
//  https://github.com/hotwired/turbo/pull/863#issuecomment-1470184953
//
// We may keep it as a defensive strategy, but it's seems better to implement
// a turbo stream response in such case.
//
document.addEventListener("turbo:frame-missing", (event) => {
  if (event.detail.response.redirected) {
    event.preventDefault()
    event.detail.visit(event.detail.response)
  }
})

// FIXME: Monkeypatch to fix turbo issue of wrong turbo-frame
// See:
//  https://github.com/hotwired/turbo/pull/579#issuecomment-1403990185
//
document.addEventListener('turbo:before-fetch-request', (event) => {
  const targetTurboFrame = event.target.getAttribute('data-turbo-frame')
  const fetchTurboFrame = event.detail.fetchOptions.headers['Turbo-Frame']

  if (targetTurboFrame && targetTurboFrame != fetchTurboFrame && document.querySelector(`turbo-frame#${targetTurboFrame}`)) {
    event.detail.fetchOptions.headers['Turbo-Frame'] = targetTurboFrame;
  }
})

// Append new notifications instead of morphing existing ones.
//
document.addEventListener("turbo:before-morph-element", (event) => {
  if (
    (event.detail.currentElement && event.detail.currentElement.id == "notifications") &&
    (event.detail.newElement     && event.detail.newElement.id     == "notifications")
  ) {
    event.preventDefault()
    event.detail.newElement.childNodes.forEach((child) => {
      event.detail.currentElement.appendChild(child)
    })
  }
})
