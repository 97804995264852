import { Controller } from "@hotwired/stimulus"

export default class SwitchFormController extends Controller {
  static targets = ["item", "input"]

  update(event) {
    let currentValues = []
    let checkedInputs = []
    const group = event.target.dataset.switchFormGroupValue

    if (event.target.dataset.selectionTarget == "checkbox") {
      checkedInputs = this.inputTargets.filter(input => input.checked)

      if (group) {
        checkedInputs = checkedInputs.filter(input => input.dataset.switchFormGroupValue === group)
      }

      if (checkedInputs.length > 0) {
        currentValues = checkedInputs.map(input => String(input.value))
      }
    } else {
      currentValues = [String(event.target.value)]
    }

    if (group) {
      this.itemTargets
        .filter(item => item.dataset.switchFormGroup === group)
        .forEach(item => {
          const expectedValues = this._getExpectedValues(item)
          const enabled = expectedValues.some(value => currentValues.includes(value))

          item.hidden = !enabled
          item.disabled = !enabled
        })
    } else {
      this.itemTargets
        .forEach(item => {
          const expectedValues = this._getExpectedValues(item)
          const enabled = expectedValues.some(value => currentValues.includes(value))

          item.hidden = !enabled
          item.disabled = !enabled
        })
    }
  }

  _getExpectedValues(item) {
    if (item.dataset.switchFormValue !== undefined) {
      return [item.dataset.switchFormValue + ""]
    } else if (item.dataset.switchFormValues !== undefined) {
      return JSON.parse(item.dataset.switchFormValues).map(String)
    }
    return []
  }
}
